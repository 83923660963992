@import '../../../scss/theme-bootstrap';

.my-account-offers-v1 {
  .my-account-touts {
    overflow: hidden;
  }
  .my-account-touts .my-account-tout {
    float: #{$rdirection};
    margin: 35px 0;
    min-height: 120px;
    @include swap_direction(padding, 0 20px 0 0);
    width: 50%;
  }
}
